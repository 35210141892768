import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization/hooks/organization';
import { useAnalytics } from './AnalyticsContext';

class PreGameAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    private readonly orgId: string | null
  ) {}

  trackPreGameViewed(props: { venueId?: string | null }): void {
    this.analytics.track('Pre Game Viewed', this.buildProps(props));
  }

  trackTeamsReRandomized(): void {
    this.analytics.track('Pre Game Teams Re-Randomized', this.buildProps());
  }

  trackCancelClicked(): void {
    this.analytics.track('Pre Game Cancel Clicked', this.buildProps());
  }

  trackEveryonesReadyClicked(props: {
    includeHostedTutorial: boolean | null; // null in case it's unavailable
  }): void {
    this.analytics.track(
      'Pre Game Everyones Ready Clicked',
      this.buildProps(props)
    );
  }

  trackInstructionClicked(props: {
    instructionBlockId: string;
    cardIndex: number;
  }): void {
    this.analytics.track(
      'Pre Game Instruction Clicked',
      this.buildProps(props)
    );
  }

  private buildProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function usePreGameAnalytics(): PreGameAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();
  return useMemo(
    () => new PreGameAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
