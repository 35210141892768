import pluralize from 'pluralize';
import { useMemo, useState } from 'react';

import {
  assertExhaustive,
  type GuessWhoBlock,
  GuessWhoBlockGameSessionStatus,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { setDifference } from '../../../../utils/common';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { Loading } from '../../../Loading';
import {
  useCohostClientIdGetter,
  useHostClientIdGetter,
  useParticipantsAsArray,
} from '../../../Player';
import { useGameSessionLocalTimer, useGameSessionStatus } from '../../hooks';
import { ondWaitEnd } from '../../OndPhaseRunner';
import { type CoordinatorControllerProps } from '../Common/CoordinatorController/CoordinatorController';
import { useStableBlock } from '../Common/hooks';
import {
  useCurrMatchPrompt,
  useCurrMatchState,
  useGeneratedMatchPrompts,
  useGuessWhoGameControlAPI,
} from './GuessWhoProvider';

function RevealControl(props: { points: number }): JSX.Element | null {
  const time = useGameSessionLocalTimer();
  const api = useGuessWhoGameControlAPI();
  const currMatchState = useCurrMatchState();
  const currMatchPrompt = useCurrMatchPrompt();
  const players = useParticipantsAsArray({
    filters: [
      'host:false',
      'cohost:false',
      'status:connected',
      'team:true',
      'staff:false',
    ],
  });
  const [triggered, setTriggered] = useState(false);
  const [hasEveryoneVoted, remainingVotes] = useMemo(() => {
    if (!currMatchPrompt) return [false, 0];

    // everyone but the responder must vote.
    const s1 = new Set(
      players.filter((p) => p.id !== currMatchPrompt.playerId).map((p) => p.id)
    );
    // all voted players
    const s2 = new Set(Object.keys(currMatchState.votes ?? {}));
    const diff = setDifference(s1, s2);
    return [diff.size === 0, diff.size];
  }, [currMatchPrompt, currMatchState.votes, players]);

  const onClick = useLiveCallback(async () => {
    if (triggered || !currMatchPrompt) return;
    setTriggered(true);
    await api.startRevealSequence(
      currMatchPrompt,
      props.points,
      players,
      'coordinator-triggered'
    );
  });

  if (time === 0) return null;

  return (
    <div className='flex flex-col items-center gap-1'>
      <button
        type='button'
        className='btn btn-primary w-full h-10 flex items-center justify-center text-sms'
        onClick={onClick}
        disabled={triggered}
      >
        {triggered ? (
          <Loading text='' imgClassName='w-5 h-5' />
        ) : (
          <>Reveal Answer</>
        )}
      </button>

      <div className='w-full bg-secondary rounded-xl px-4 py-3 mt-2'>
        <div className='text-center text-xs text-tertiary font-bold'>
          Auto-progressing in {time}s...
        </div>
      </div>

      <div className='w-full bg-secondary rounded-xl px-4 py-3 mt-2'>
        <div className='text-center text-xs text-tertiary font-bold'>
          {hasEveryoneVoted
            ? 'Everyone has voted!'
            : `Waiting on ${remainingVotes} ${pluralize(
                'votes',
                remainingVotes
              )}...`}
        </div>
      </div>
    </div>
  );
}

function ProgressControl(): JSX.Element | null {
  const time = useGameSessionLocalTimer();
  const api = useGuessWhoGameControlAPI();
  const state = useCurrMatchState();
  const generatedMatchPrompts = useGeneratedMatchPrompts();
  const numPrompts = generatedMatchPrompts?.sequence.length ?? 0;
  const [nextPlayerTriggered, setNextPlayerTriggered] = useState(false);
  const [endTriggered, setEndTriggered] = useState(false);
  const getHostClientId = useHostClientIdGetter();
  const getCohostClientId = useCohostClientIdGetter();

  const onNextPlayer = useLiveCallback(async () => {
    if (nextPlayerTriggered) return;
    setNextPlayerTriggered(true);
    await api.endReveal(state.index);
  });

  const onEndAndShowSummary = async () => {
    if (endTriggered) return;
    setEndTriggered(true);
    await api.endMatch([getHostClientId(), getCohostClientId()]);
    await ondWaitEnd();
  };

  const hasMoreQuestions = state.index + 1 < numPrompts;

  return (
    <div className='flex flex-col items-center gap-2.5'>
      {hasMoreQuestions && (
        <button
          type='button'
          className='btn btn-primary w-full h-10 flex items-center justify-center text-sms'
          onClick={onNextPlayer}
          disabled={nextPlayerTriggered || endTriggered}
        >
          {nextPlayerTriggered ? (
            <Loading text='' imgClassName='w-5 h-5' />
          ) : (
            <>Next Player</>
          )}
        </button>
      )}
      <button
        type='button'
        className={`
          btn ${
            hasMoreQuestions ? 'btn-secondary' : 'btn-primary'
          } w-full h-10 flex items-center justify-center gap-1 text-sms
        `}
        onClick={onEndAndShowSummary}
        disabled={nextPlayerTriggered || endTriggered}
      >
        {endTriggered ? (
          <Loading text='' imgClassName='w-5 h-5' />
        ) : (
          <>
            <FilledSquareIcon className='w-4 h-4 fill-current' />
            {'End & Show Summary'}
          </>
        )}
      </button>
      <div className='text-2xs text-tertiary font-bold'>
        Auto-progressing in {time}s...
      </div>
    </div>
  );
}

function Controls(props: { block: GuessWhoBlock }): JSX.Element | null {
  const generatedMatchPrompts = useGeneratedMatchPrompts();
  const prompt = useCurrMatchPrompt();
  const state = useCurrMatchState();

  if (
    !generatedMatchPrompts ||
    generatedMatchPrompts.sequence.length === 0 ||
    !prompt
  ) {
    return null;
  }

  switch (state.phase) {
    case 'vote':
      return <RevealControl points={props.block.fields.pointsPerCorrect} />;
    case 'reveal-transition':
      return null;
    case 'reveal':
      return <ProgressControl />;
    case 'done':
      return null;
    default:
      assertExhaustive(state.phase);
      break;
  }
  return null;
}

export function GuessWhoBlockCoordinatorController(
  props: CoordinatorControllerProps<GuessWhoBlock>
): JSX.Element | null {
  const block = useStableBlock(props.selectedBlock);
  const gss = useGameSessionStatus<GuessWhoBlockGameSessionStatus>();

  switch (gss) {
    case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_COUNTING:
      return <Controls block={block} />;
    case GuessWhoBlockGameSessionStatus.LOADED:
    case GuessWhoBlockGameSessionStatus.INTRO:
    case GuessWhoBlockGameSessionStatus.PROMPT_INIT:
    case GuessWhoBlockGameSessionStatus.PROMPT_COUNTING:
    case GuessWhoBlockGameSessionStatus.PROMPT_DONE:
    case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_INIT:
    case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_DONE:
    case GuessWhoBlockGameSessionStatus.RESULTS:
    case GuessWhoBlockGameSessionStatus.SCOREBOARD:
    case GuessWhoBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
