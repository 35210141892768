import pluralize from 'pluralize';

import { useDebouncedValue } from '../../hooks/useDebouncedValue';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { GamePackUtils } from '../Game/GamePack/utils';
import { useFetchGameSessionGamePack } from '../Game/hooks';
import { useTriggerBookNow } from '../Product/BookNow';
import { useVenueDerivedSettings } from '../Venue';

export function LobbyUpgradeCallout() {
  const enabled = useFeatureQueryParam('venue-capacity-check');
  const { value, isInitializing } = useDebouncedValue(
    useVenueDerivedSettings(),
    {
      settleAfterMs: 250,
      keepPreviousValue: true,
    }
  );
  if (
    !enabled ||
    !value ||
    isInitializing ||
    !value.seatCap ||
    !value.tryItNowEnabled
  )
    return null;
  return <LobbyUpgradeCalloutInternal seatCap={value.seatCap} />;
}

function LobbyUpgradeCalloutInternal(props: { seatCap: number }) {
  const { value: gamePack, isInitializing: isLoadingGamePack } =
    useDebouncedValue(useFetchGameSessionGamePack(), {
      settleAfterMs: 500,
      keepPreviousValue: true,
    });
  const triggerBookNow = useTriggerBookNow();
  const handleClick = useLiveCallback(() => {
    if (gamePack) triggerBookNow(gamePack, 'lobby-upgrade-callout');
  });
  const otpCheckout = useFeatureQueryParam('otp-checkout');

  if (!gamePack || isLoadingGamePack) return null;

  const hasOneTimePurchasePricingTable =
    otpCheckout && GamePackUtils.ActivePrices(gamePack).length > 0;

  return (
    <div className='-ml-5 bg-gradient-to-bl from-white to-pairing-end p-px pl-0 rounded-tr-xl rounded-br-xl animate-[slide-in-to-right_0.25s_ease_1] transform'>
      <div className='w-full h-full px-4 bg-main-layer shadow-lg rounded-tr-xl rounded-br-xl flex flex-col items-center justify-center py-3.5 gap-3.5 '>
        <button
          type='button'
          className={`
            w-24 h-8 lg:w-32 lg:h-10 xl:w-40 xl:h-10
            btn bg-red-006 text-white text-xs lg:text-sms
            hover:bg-[#FF2E53] transition-colors
          `}
          onClick={handleClick}
        >
          {hasOneTimePurchasePricingTable ? <>Book Now</> : <>Upgrade</>}
        </button>
        <div className='hidden md:flex flex-col text-white md:text-2xs lg:text-xs text-center'>
          <div className='font-bold'>Seat Limit</div>
          <div className=''>
            Up to {props.seatCap} {pluralize('player', props.seatCap)}
          </div>
        </div>
      </div>
    </div>
  );
}
